export const ArrayListasDesplegables = {
  TIPO_DE_NEGOCIO: [
    {
      Id: 1,
      Nombre: "Arriendo",
    },
    {
      Id: 2,
      Nombre: "Compra",
    },
  ],
  SUBMERCADO: [
    {
      Id: 1,
      Nombre: "E/S Detal",
    },
    {
      Id: 2,
      Nombre: "Industria",
    },
  ],
  PLANTA: [
    {
      Id: 1,
      Nombre: "Puente Aranda",
    },
    {
      Id: 2,
      Nombre: "Mancilla",
    },
    {
      Id: 3,
      Nombre: "Gualanday",
    },
    {
      Id: 4,
      Nombre: "Neiva",
    },
    {
      Id: 5,
      Nombre: "Yumbo",
    },
    {
      Id: 6,
      Nombre: "Buenaventura",
    },
    {
      Id: 7,
      Nombre: "Cartago",
    },
    {
      Id: 8,
      Nombre: "Cartagena",
    },
    {
      Id: 9,
      Nombre: "Galapa",
    },
    {
      Id: 10,
      Nombre: "Medellin",
    },
    {
      Id: 11,
      Nombre: "Bucaramanga",
    },
    {
      Id: 12,
      Nombre: "La Dorada",
    },
    {
      Id: 13,
      Nombre: "Rio Sogamoso",
    },
  ],
  LISTA_SI_NO: [
    {
      Id: 1,
      Nombre: "SI",
    },
    {
      Id: 2,
      Nombre: "NO",
    },
  ],
  TIPO_DE_PROYECTO: [
    {
      Id: 1,
      Nombre: "Break-in (cambio de descuento ya)",
    },
    {
      Id: 2,
      Nombre: "Reaseguramiento/Rebranding",
    },
    {
      Id: 3,
      Nombre: "Cambio de bandera",
    },
    {
      Id: 4,
      Nombre: "NTI",
    },
    {
      Id: 5,
      Nombre: "Renovación Automática (Solo Aplica para Industria)",
    },
  ],
};
