import React, { useState, useEffect } from "react";
import { logoPrimaxColor, logoPrimaxVertical } from "../imagenes";

const PaginaPrincipal = () => {
  const [tamañoPantalla, setTamañoPantalla] = useState({
    ancho: window.innerWidth,
    alto: window.innerHeight,
  });

  const updateScreenSize = () => {
    setTamañoPantalla({
      ancho: window.innerWidth,
      alto: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  return (
    <div className="min-h-screen w-full flex flex-wrap items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover">
      <img
        className="w-10/12 border-opacity-50"
        src={tamañoPantalla?.ancho < 768 ? logoPrimaxVertical : logoPrimaxColor}
        alt="fondo"
      />
    </div>
  );
};

export default PaginaPrincipal;
