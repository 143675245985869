import React from "react";
import { TEXTO_VISUAL, RUTAS_APP } from "../../constantes";
import MenuDinamico from "./MenuDinamico";
import { monedaLogo, graficaLogo, logoEngranes } from "../../imagenes";

const BarraNavegacion = () => {
  const arrayMenus = [
    {
      titulo: TEXTO_VISUAL.MODELO_OD,
      ruta: RUTAS_APP.FORMULARIOS.MODELO_OD,
      imagen: monedaLogo,
      deshabilitado: false,
      subMenus: [],
    },
    {
      titulo: TEXTO_VISUAL.MODELO_DODO,
      ruta: RUTAS_APP.FORMULARIOS.MODELO_DODO,
      imagen: graficaLogo,
      deshabilitado: false,
      subMenus: [],
    },
    {
      titulo: TEXTO_VISUAL.MODELO_INDUSTRIA,
      ruta: RUTAS_APP.FORMULARIOS.MODELO_INDUSTRIA,
      imagen: logoEngranes,
      deshabilitado: false,
      subMenus: [],
    },
  ];

  return (
    <>
      <MenuDinamico datos={arrayMenus} />
    </>
  );
};

export default BarraNavegacion;
