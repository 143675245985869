import React from "react";
import { NumericFormat } from "react-number-format";

const InputPorcentaje = ({
  onValueChange,
  estilosContenedor,
  estilosInput,
  placeholder,
  titulo,
  deshabilitado,
  valor,
  onFocus,
  deshabilitarEscalaDecimal,
  onBlur,
}) => {
  return (
    <div
      className={`${estilosContenedor} flex justify-start items-center flex-wrap`}
    >
      <label className="block text-13px text-primaxColorSecundario font-bold my-2px">
        {titulo}
      </label>
      <NumericFormat
        thousandSeparator={"."}
        decimalSeparator={","}
        decimalScale={deshabilitarEscalaDecimal ? 1 : 2}
        fixedDecimalScale={deshabilitarEscalaDecimal ? false : true}
        suffix={"%"}
        value={valor}
        defaultValue={""}
        onValueChange={(values, sourceInfo) => {
          if (sourceInfo.source === "event") {
            onValueChange(values);
          }
        }}
        className={estilosInput}
        placeholder={placeholder}
        disabled={deshabilitado}
        allowEmptyFormatting={true}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
};

export default InputPorcentaje;
