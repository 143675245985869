import { ArrayListasDesplegables } from "./arregloLIstasDesplegables";

export const ArregloInputsModeloDODO = [
  {
    titulo: "Generales",
    contenido: [
      {
        titulo: "Nombre",
        nombre: "nombre",
        requerido: true,
        tipo: "text",
        campo: "INPUT",
      },
      {
        titulo: "Submercado",
        nombre: "submercado",
        requerido: true,
        tipo: "text",
        campo: "LISTA",
        opcionesLista: ArrayListasDesplegables.SUBMERCADO,
      },
      {
        titulo: "Planta",
        nombre: "planta",
        requerido: true,
        tipo: "text",
        campo: "LISTA",
        opcionesLista: ArrayListasDesplegables.PLANTA,
      },
      {
        titulo: "Tipo de Proyecto",
        nombre: "tipoProyecto",
        requerido: true,
        tipo: "text",
        campo: "LISTA",
        opcionesLista: ArrayListasDesplegables.TIPO_DE_PROYECTO,
      },
    ],
  },
  {
    titulo: "Fechas",
    contenido: [
      {
        titulo: "Fecha de vencimiento contrato actual (MM/DD/AAAA)",
        nombre: "fechaVencimientoContratoActual",
        requerido: true,
        tipo: "date",
        campo: "INPUT",
      },
      {
        titulo: "Fecha inicio nuevo descuento (MM/DD/AAAA)",
        nombre: "fechaInicioNuevoDescuento",
        requerido: true,
        tipo: "date",
        campo: "INPUT",
      },
      {
        titulo: "Fecha desembolso MAP o LEASE o CAPEX (MM/DD/AAAA)",
        nombre: "fechaDesembolso",
        requerido: true,
        tipo: "date",
        campo: "INPUT",
      },
    ],
  },

  {
    titulo: "Condiciones Actuales",
    contenido: [
      {
        titulo: "MAP Existente ($MCOP)",
        nombre: "mapExistente",
        requerido: false,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "Días de plazo del contrato anterior",
        nombre: "diasPlazoContratoAnterior",
        requerido: true,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "Descuento %MM del contrato anterior",
        nombre: "descuentoContratoAnterior",
        requerido: true,
        tipo: "number",
        campo: "INPUTPORCENTAJE",
      },
    ],
  },
  {
    titulo: "Volumen Actual (Do Nothing)",
    contenido: [
      {
        titulo: "Gasolina Corriente (KGM)",
        nombre: "gasolinaCorriente",
        requerido: true,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "Diesel (KGM)",
        nombre: "diesel",
        requerido: true,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "Extra (KGM)",
        nombre: "extra",
        requerido: true,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "Max Pro Diesel (KGM)",
        nombre: "maxProDiesel",
        requerido: true,
        tipo: "number",
        campo: "INPUT",
      },
    ],
  },
  {
    titulo: "Volumen Esperado (Nuevo Total)",
    contenido: [
      {
        titulo: "Gasolina Corriente (KGM)",
        nombre: "gasolinaCorrienteNuevoTotal",
        requerido: true,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "Diesel (KGM)",
        nombre: "dieselNuevoTotal",
        requerido: true,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "Extra (KGM)",
        nombre: "extraNuevoTotal",
        requerido: true,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "Max Pro Diesel (KGM)",
        nombre: "maxProDieselNuevoTotal",
        requerido: true,
        tipo: "number",
        campo: "INPUT",
      },
    ],
  },
  {
    titulo: "Nuevas Condiciones Comerciales",
    contenido: [
      {
        titulo: "Meses nuevo contrato (MESES)",
        nombre: "mesesNuevoContrato",
        requerido: true,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "Días de Plazo",
        nombre: "diasPlazo",
        requerido: true,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "Zona de Frontera?",
        nombre: "zonaFrontera",
        requerido: true,
        tipo: "text",
        campo: "LISTA",
        opcionesLista: ArrayListasDesplegables.LISTA_SI_NO,
      },
      {
        titulo: "Nuevo Descuento",
        nombre: "nuevoDescuento",
        requerido: true,
        tipo: "number",
        campo: "INPUTPORCENTAJE",
      },
      {
        titulo: "Nuevo MAP ($MCOP)",
        nombre: "nuevoMAP",
        requerido: false,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "Nuevo LEASE ($MCOP)",
        nombre: "nuevLEASE",
        requerido: false,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "CAPEX ($MCOP)",
        nombre: "capex",
        requerido: false,
        tipo: "number",
        campo: "INPUT",
      },
    ],
  },
];
