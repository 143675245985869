import React, { useContext } from "react";
import { ContextoAplicacion } from "../../contextos";
import Modal from "./Modal";

const ModalPrimax = () => {
  const contexto = useContext(ContextoAplicacion);

  return (
    <Modal open={contexto.modal.abierto}>
      <div className="w-full flex flex-wrap justify-center items-center uppercase">
        <div className="w-full mt-90px">
          <span className="text-primaxColorPrimario text-17px  leading-20px font-bold uppercase">
            {contexto.modal.titulo}
          </span>
          <span className="text-primaxColorPrimario  text-17px  mb-50px leading-20px uppercase">
            {contexto.modal.subtitulo}
          </span>
        </div>
        <div className="w-full flex flex-wrap justify-center mt-50px leading-20px text-17px text-primaxColorSecundario uppercase">
          {contexto.modal.contenido}
        </div>
        {contexto.modal.botones.length > 0 ? (
          <div className="w-full flex justify-evenly items-center mt-50px mb-80px text-17px text-primaxColorPrimario -verde-7 ">
            {contexto.modal.botones.map((boton) => (
              <button
                key={boton.nombre}
                className="hover:opacity-80 items-center group relative w-44 h-14 flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-center bg-primaxColorPrimario bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaxColorSecundario"
                type="button"
                onClick={boton.click}
              >
                {" "}
                {boton.nombre}
              </button>
            ))}
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
export default ModalPrimax;
