import React from "react";
import ReactDom from "react-dom";

const MODAL_STYLES = {
  backgroundColor: "#fff",
  padding: "50px",
  zIndex: 1000,
  borderRadius: "2%",
  textAlign: "center",
  overflow: "auto",
  maxHeight: "70vh",
};

const OVERLAY_STYLE = {
  position: "fixed",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  rigth: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, .7)",
  zIndex: 1000,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const Modal = ({ open, children }) => {
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLE}>
        <div
          className="border border-cendiatra w-4/5 lg:w-3/5 uppercase"
          style={MODAL_STYLES}
        >
          {children}
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default Modal;
