import { ArrayListasDesplegables } from "./arregloLIstasDesplegables";

export const ArregloInputsModeloOd = [
  {
    titulo: "",
    contenido: [
      {
        titulo: "Nombre",
        nombre: "nombre",
        requerido: true,
        tipo: "text",
        campo: "INPUT",
      },
    ],
  },
  {
    titulo: "Volumen",
    contenido: [
      {
        titulo: "Volumen Corriente Gl/Mes",
        nombre: "volumenCorriente",
        requerido: true,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "Volumen Diesel Gl/Mes",
        nombre: "volumenDiesel",
        requerido: true,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "Volumen Extra Gl/Mes",
        nombre: "volumenExtra",
        requerido: true,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "Volumen Max-D Gl/Mes",
        nombre: "volumenMaxD",
        requerido: true,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "Terminal (Planta)",
        nombre: "planta",
        requerido: true,
        tipo: "text",
        campo: "LISTA",
        opcionesLista: ArrayListasDesplegables.PLANTA,
      },
    ],
  },
  {
    titulo: "Margen (WAM)",
    contenido: [
      {
        titulo: "Margen Minorista Corriente  $COP/Gal",
        nombre: "margenCorriente",
        requerido: true,
        tipo: "number",
        campo: "INPUTMONEDA",
      },
      {
        titulo: "Margen Minorista Diesel  $COP/Gal",
        nombre: "margenDiesel",
        requerido: true,
        tipo: "number",
        campo: "INPUTMONEDA",
      },
      {
        titulo: "Margen Minorista Extra $COP/Gal",
        nombre: "margenExtra",
        requerido: true,
        tipo: "number",
        campo: "INPUTMONEDA",
      },
      {
        titulo: "Margen Minorista Max-D  $COP/Gal",
        nombre: "margenMaxD",
        requerido: true,
        tipo: "number",
        campo: "INPUTMONEDA",
      },
    ],
  },

  {
    titulo: "Condiciones Actuales (Solo si es Existente)",
    contenido: [
      {
        titulo: "Descuento",
        nombre: "descuento",
        requerido: false,
        tipo: "number",
        campo: "INPUTPORCENTAJE",
      },

      {
        titulo: "Vencimiento (MM/DD/AAAA)",
        nombre: "fechaVencimiento",
        requerido: false,
        tipo: "date",
        campo: "INPUT",
      },
    ],
  },
  {
    titulo: "Working Capital Minorista (Solo si hay Volumen Credito)",
    contenido: [
      {
        titulo: "Dias de plazo clientes crédito",
        nombre: "diasPlazoClientesCredito",
        requerido: false,
        tipo: "number",
        campo: "INPUT",
      },
      {
        titulo: "Volumen a credito %",
        nombre: "volumenCredito",
        requerido: false,
        tipo: "number",
        campo: "INPUTPORCENTAJE",
      },
    ],
  },
  {
    titulo: "Nuevas condiciones Op Directa",
    contenido: [
      {
        titulo: "Tipo de Negocio  (Alquiler / Compra)",
        nombre: "tipoNegocio",
        requerido: true,
        tipo: "text",
        campo: "LISTA",
        opcionesLista: ArrayListasDesplegables.TIPO_DE_NEGOCIO,
        validar: true,
      },
      {
        titulo: "Fecha inicio  (MM/DD/AAAA)",
        nombre: "fechaInicio",
        requerido: true,
        tipo: "date",
        campo: "INPUT",
      },
    ],
  },
  {
    titulo: "Condiciones para Compra",
    contenido: [
      {
        titulo: "Primer Pago ($MCOP)",
        nombre: "primerPago",
        requerido: true,
        tipo: "text",
        campo: "INPUTMONEDA",
      },
      {
        titulo: "Segundo Pago ($MCOP)",
        nombre: "segundoPago",
        requerido: false,
        tipo: "text",
        campo: "INPUTMONEDA",
      },
      {
        titulo: "Tercer Pago ($MCOP)",
        nombre: "tercerPago",
        requerido: false,
        tipo: "text",
        campo: "INPUTMONEDA",
      },
      {
        titulo: "Arras ($MCOP)",
        nombre: "arras",
        requerido: false,
        tipo: "text",
        campo: "INPUTMONEDA",
      },

      {
        titulo: "Fecha de Arras (MM/DD/AAAA)",
        nombre: "fechaArras",
        requerido: false,
        tipo: "date",
        campo: "INPUT",
      },
    ],
  },
  {
    titulo: "Condiciones para Arriendo",
    contenido: [
      {
        titulo: "Arriendo Fijo ($MCOP)",
        nombre: "arriendoFijo",
        requerido: true,
        tipo: "text",
        campo: "INPUTMONEDA",
      },
      {
        titulo: "Arriendo Variable %",
        nombre: "arriendoVariable",
        requerido: true,
        tipo: "text",
        campo: "INPUTPORCENTAJE",
      },
    ],
  },
  {
    titulo: "Condiciones Generales",
    contenido: [
      {
        titulo: "Flete (COP/gl)",
        nombre: "flete",
        requerido: true,
        tipo: "text",
        campo: "INPUTMONEDA",
      },
      {
        titulo: "Otros Ingresos NETOS ($MCOP)",
        nombre: "otrosIngresosNetos",
        requerido: false,
        tipo: "text",
        campo: "INPUTMONEDA",
      },
      {
        titulo: "Capex Retoma ($MCOP)",
        nombre: "capexRetoma",
        requerido: true,
        tipo: "text",
        campo: "INPUTMONEDA",
      },
      {
        titulo: "Capex Construccion ($MCOP)",
        nombre: "capexConstruccion",
        requerido: false,
        tipo: "text",
        campo: "INPUTMONEDA",
      },
    ],
  },
];
