export const guardarInformacionEnLocalStorage = (
  nombreElemento,
  informacion
) => {
  localStorage.setItem(nombreElemento, JSON.stringify(informacion));
};

export const dividirPalabra = (palabra) => {
  if (typeof palabra === "number") {
    return palabra;
  }
  return palabra.replace(/([a-z])([A-Z])/g, "$1 $2");
};
