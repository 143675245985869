import { useContext } from "react";
import { ContextoAplicacion } from "../contextos";

export const useModal = () => {
  const contextoAplicacion = useContext(ContextoAplicacion);

  const cerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: "",
      contenido: "",
      botones: [],
    });
  };

  return [cerrarModal];
};
