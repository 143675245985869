import React from "react";
import { NavegacionContexto, AplicacionContexto } from "./contextos";
import { BrowserRouter } from "react-router-dom";
import { BarraNavegacion } from "./componentes/navegacion";
import Router from "./Router.js";
import { ModalPrimax, Spinner } from "./componentes/modales";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Login from "./paginas/Login";

function App() {
  return (
    <>
      <AuthenticatedTemplate>
        <AplicacionContexto>
          <NavegacionContexto>
            <BrowserRouter>
              <ModalPrimax />
              <Spinner message={"PRIMAX"} />

              <BarraNavegacion />
              <Router />
            </BrowserRouter>
          </NavegacionContexto>
        </AplicacionContexto>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
