import React from "react";

const ListaDesplegable = ({
  id,
  onChange,
  titulo,
  opciones,
  valor,
  deshabilitado,
  funcion,
  onBlur,
  estilosLista,
  deshabilitarElegir,
  placeholder,
  contexto,
  numeroPagina,
}) => {
  const valorSeleccionado = opciones.find((val) => {
    return val.Id === valor;
  });

  if (contexto && contexto.numeroPagina === numeroPagina) {
    if (valor && contexto.errores.includes(id)) {
      contexto.setErrores(contexto.errores.filter((x) => x !== id));
    }
    if (!valor && !contexto.errores.includes(id)) {
      contexto.setErrores([...contexto.errores, id]);
    }
  }
  return (
    <div className={"w-full flex justify-start items-center flex-wrap"}>
      {titulo ? (
        <label className=" text-13px w-full text-primaxColorSecundario font-bold text-start my-5px">
          {titulo}
        </label>
      ) : null}
      <select
        className={estilosLista}
        onChange={funcion ? (e) => funcion(e) : onChange}
        onBlur={onBlur}
        value={valorSeleccionado?.Id ?? valor}
        disabled={deshabilitado}
      >
        {!deshabilitarElegir && placeholder ? (
          <option key="-1" value="">
            {" "}
            {placeholder}{" "}
          </option>
        ) : null}
        {!deshabilitarElegir && !placeholder ? (
          <option key="-1" value="">
            {" "}
            ELEGIR{" "}
          </option>
        ) : null}
        {opciones.map((item) => (
          <option key={item.Nombre} value={item.Nombre}>
            {item.Nombre}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ListaDesplegable;
