import React from "react";

const ErroresInput = ({ erroresFormulario, nombre, tipoError, mensaje }) => {
  return (
    <span className="text-primaxColorPrimario w-full text-13px">
      {erroresFormulario?.[nombre]?.type === tipoError ? mensaje : null}
    </span>
  );
};

export default ErroresInput;
