import React, { useContext } from "react";
import {
  ContenedorAgruparInformacion,
  ContenedorPagina,
} from "../componentes/contenedores";
import { FormularioDinamico } from "../componentes/formularios";
import { ArregloInputsModeloOd } from "../utilidades";
import { guardarInformacionEnLocalStorage } from "../utilidades";
import { TEXTO_VISUAL } from "../constantes";
import { obtenerModeloOd } from "../servicios";
import { ContextoAplicacion } from "../contextos";
import { useModal } from "../hooks";
import moment from "moment/moment";

const ModeloOd = () => {
  const contextoAplicacion = useContext(ContextoAplicacion);

  const { setModal } = contextoAplicacion;

  const [cerrarModal] = useModal();

  const crearModeloParaGuardar = async (datosFormulario) => {
    console.log(datosFormulario?.fechaVencimiento);
    const modeloAGuardar = {
      nombre: datosFormulario.nombre ?? "",
      //VOLUMEN
      volumenCorriente: parseInt(datosFormulario.volumenCorriente) ?? 0,
      volumenDiesel: parseInt(datosFormulario.volumenDiesel) ?? 0,
      volumenExtra: parseInt(datosFormulario.volumenExtra) ?? 0,
      volumenMaxD: parseInt(datosFormulario.volumenMaxD) ?? 0,
      planta: datosFormulario.planta ?? "", // lista desplegable
      //Margen (WAM)
      margenCorriente: datosFormulario.margenCorriente ?? 0,
      margenDiesel: datosFormulario.margenDiesel ?? 0,
      margenExtra: datosFormulario.margenExtra ?? 0,
      margenMaxD: datosFormulario.margenMaxD ?? 0,
      //"Condiciones Actuales (Solo si es Existente)"
      descuento: datosFormulario.descuento ?? 0,
      fechaVencimiento: datosFormulario.fechaVencimiento ?? "",
      //"Working Capital Minorista (Solo si hay Volumen Credito)"
      diasPlazoClientesCredito:
        parseInt(datosFormulario.diasPlazoClientesCredito) ?? 0,
      volumenCredito: datosFormulario.volumenCredito ?? 0,
      //"Nuevas condiciones Op Directa"
      tipoNegocio: datosFormulario.tipoNegocio ?? "",
      fechaInicio: datosFormulario.fechaInicio ?? "",
      //"Condiciones para Compra"
      primerPago: datosFormulario.primerPago ?? 0,
      segundoPago: datosFormulario.segundoPago ?? 0,
      tercerPago: datosFormulario.tercerPago ?? 0,
      arras: datosFormulario.arras ?? 0,
      fechaArras: datosFormulario.fechaArras ?? "",
      //"Condiciones para Arriendo"
      arriendoFijo: datosFormulario.arriendoFijo ?? 0,
      arriendoVariable: datosFormulario.arriendoVariable ?? 0,
      //"Condiciones Generales"
      flete: datosFormulario.flete ?? 0,
      otrosIngresosNetos: datosFormulario.otrosIngresosNetos ?? 0,
      capexRetoma: datosFormulario.capexRetoma ?? 0,
      capexConstruccion: datosFormulario.capexConstruccion ?? 0,
      fechaInicioFormateada:
        moment(datosFormulario?.fechaInicio)?.format("MM/DD/YYYY") ?? "",
    };

    if (datosFormulario?.fechaVencimiento) {
      modeloAGuardar.fechaVencimientoFormateada = moment(
        datosFormulario.fechaVencimiento
      )?.format("MM/DD/YYYY");
    }
    if (datosFormulario?.fechaArras) {
      modeloAGuardar.fechaArrasFormateada = moment(
        datosFormulario?.fechaArras
      )?.format("MM/DD/YYYY");
    }

    guardarInformacionEnLocalStorage(TEXTO_VISUAL.MODELO_OD, modeloAGuardar);
    contextoAplicacion.setCargando(true);
    try {
      const respuesta = await obtenerModeloOd(modeloAGuardar);
      if (respuesta?.status === 200) {
        contextoAplicacion.setCargando(false);
        const formateoInformacion = {
          Volumen: `${respuesta?.data?.volumen} KGAL`,
          "Costo Operacional": `${respuesta?.data?.costoOperacional} KCOP`,
          "Otros Ingresos": `${respuesta?.data?.otrosIngresos} KCOP`,
          "Arriendo Total Mensual": `${respuesta?.data?.arriendoTotalMensual} KCOP`,
          "Arriendo Fijo Año": `${respuesta?.data?.arriendoFijoAnnio} KCOP`,
          "Arriendo Variable Año": `${respuesta?.data?.arriedoVariableAnnio} KCOP`,
          "Capex Compra": `${respuesta?.data?.capexCompra} KCOP`,
          "Capex Proyecto": `${respuesta?.data?.capexProyecto} KCOP`,
          Tir: respuesta?.data?.tir,
          vpn: `${respuesta?.data?.vpn} KCOP`,
          "Margen Ponderado": `${respuesta?.data?.margenPonderado} COP/GAL`,
          Ebitda: `${respuesta?.data?.ebitda} $MCOP`,
          rod: `${respuesta?.data?.rod} $MCOP`,
        };

        setModal({
          abierto: true,
          titulo: `Consulta Modelo Operación Directa - ${respuesta?.data?.nombre}`,
          contenido: (
            <ContenedorAgruparInformacion
              datosParaOrganizar={formateoInformacion}
              porcentajeTirs={respuesta?.data?.porcentajeTirs}
            />
          ),
          botones: [
            {
              nombre: "Regresar",
              click: cerrarModal,
            },
          ],
        });
      }
    } catch (error) {
      console.log(error);
      contextoAplicacion.setCargando(false);
    }
  };

  return (
    <ContenedorPagina tituloPagina={TEXTO_VISUAL.MODELO_OD}>
      <FormularioDinamico
        arrayInputs={ArregloInputsModeloOd}
        funcionGuardarModelo={crearModeloParaGuardar}
        formulario={TEXTO_VISUAL.MODELO_OD}
      />
    </ContenedorPagina>
  );
};

export default ModeloOd;
