import React, { useContext } from "react";
import { ContenedorPagina } from "../componentes/contenedores";
import { ArregloInputsModeloDODO } from "../utilidades";
import { FormularioDinamico } from "../componentes/formularios";
import { guardarInformacionEnLocalStorage } from "../utilidades";
import { TEXTO_VISUAL } from "../constantes";
import { obtenerModeloDoDo } from "../servicios";
import { useModal } from "../hooks";
import { ContextoAplicacion } from "../contextos";
import { ContenedorAgruparInformacion } from "../componentes/contenedores";
import moment from "moment";

const ModeloDodo = () => {
  const contextoAplicacion = useContext(ContextoAplicacion);

  const { setModal } = contextoAplicacion;
  const [cerrarModal] = useModal();
  const crearModeloParaGuardar = async (datosFormulario) => {
    const modeloAGuardar = {
      nombre: datosFormulario.nombre ?? "",
      submercado: datosFormulario.submercado ?? "",
      planta: datosFormulario.planta ?? "",
      tipoProyecto: datosFormulario.tipoProyecto ?? "",
      fechaVencimientoContratoActual:
        datosFormulario.fechaVencimientoContratoActual ?? "",
      fechaInicioNuevoDescuento:
        datosFormulario.fechaInicioNuevoDescuento ?? "",
      fechaDesembolso: datosFormulario.fechaDesembolso ?? "",
      mapExistente: parseInt(datosFormulario.mapExistente) ?? "",
      diasPlazoContratoAnterior:
        parseInt(datosFormulario.diasPlazoContratoAnterior) ?? "",
      descuentoContratoAnterior:
        parseInt(datosFormulario.descuentoContratoAnterior) ?? "",
      gasolinaCorriente: parseInt(datosFormulario.gasolinaCorriente) ?? "", // volumen
      diesel: parseInt(datosFormulario.diesel) ?? "",
      extra: parseInt(datosFormulario.extra) ?? "",
      maxProDiesel: parseInt(datosFormulario.maxProDiesel) ?? "",
      gasolinaCorrienteNuevoTotal:
        parseInt(datosFormulario.gasolinaCorrienteNuevoTotal) ?? 0,
      dieselNuevoTotal: parseInt(datosFormulario.dieselNuevoTotal) ?? 0,
      extraNuevoTotal: parseInt(datosFormulario.extraNuevoTotal) ?? 0,
      maxProDieselNuevoTotal:
        parseInt(datosFormulario.maxProDieselNuevoTotal) ?? 0,
      mesesNuevoContrato: parseInt(datosFormulario.mesesNuevoContrato) ?? 0,
      diasPlazo: parseInt(datosFormulario.diasPlazo) ?? 0,
      zonaFrontera: datosFormulario.zonaFrontera ?? "",
      nuevoDescuento: parseInt(datosFormulario.nuevoDescuento) ?? 0,
      nuevoMAP: parseInt(datosFormulario.nuevoMAP) ?? 0,
      nuevLEASE: parseInt(datosFormulario.nuevLEASE) ?? 0,
      capex: parseInt(datosFormulario.capex) ?? 0,
      fechaVencimientoContratoActualFormateada:
        moment(datosFormulario.fechaVencimientoContratoActual)?.format(
          "MM/DD/YYYY"
        ) ?? "",
      fechaInicioNuevoDescuentoFormateada:
        moment(datosFormulario.fechaInicioNuevoDescuento)?.format(
          "MM/DD/YYYY"
        ) ?? "",
      fechaDesembolsoFormateada:
        moment(datosFormulario.fechaDesembolso)?.format("MM/DD/YYYY") ?? "",
    };
    guardarInformacionEnLocalStorage(TEXTO_VISUAL.MODELO_DODO, modeloAGuardar);
    contextoAplicacion.setCargando(true);
    try {
      const respuesta = await obtenerModeloDoDo(modeloAGuardar);
      if (respuesta?.status === 200) {
        const formateoInformacion = {
          Duración: `${respuesta?.data?.duracion} Años`,
          Volumen: `${respuesta?.data?.volumen} KGM`,
          "Volumen Contrato": `${respuesta?.data?.volumenContrato} KGAL`,
          Plazo: ` ${respuesta?.data?.plazo} Días`,
          Descuento: `${respuesta?.data?.descuento}MM`,
          Map: ` ${respuesta?.data?.map} $KCOP`,
          Tir: ` ${respuesta?.data?.tir}`,
          vpn: `${respuesta?.data?.vpn} KCOP$, @18%`,
        };

        contextoAplicacion.setCargando(false);
        setModal({
          abierto: true,
          titulo: `Consulta Modelo DoDo - ${respuesta?.data?.nombre}`,
          contenido: (
            <ContenedorAgruparInformacion
              datosParaOrganizar={formateoInformacion}
              porcentajeTirs={respuesta?.data?.porcentajeTirs}
            />
          ),
          botones: [
            {
              nombre: "Regresar",
              click: cerrarModal,
            },
          ],
        });
      }
    } catch (error) {
      console.log(error);
      contextoAplicacion.setCargando(false);
    }
  };
  return (
    <ContenedorPagina tituloPagina={TEXTO_VISUAL.MODELO_DODO}>
      <FormularioDinamico
        arrayInputs={ArregloInputsModeloDODO}
        funcionGuardarModelo={crearModeloParaGuardar}
        formulario={TEXTO_VISUAL.MODELO_DODO}
      />
    </ContenedorPagina>
  );
};

export default ModeloDodo;
