import React, { useState, createContext } from "react";

export const ContextoNavegacion = createContext("");

export const NavegacionContexto = ({ children }) => {
  const [abrirMenu, setAbrirMenu] = useState(false);
  const [enlaceActivo, setEnlaceActivo] = useState("services");

  return (
    <ContextoNavegacion.Provider
      value={{
        abrirMenu: abrirMenu,
        setAbrirMenu: setAbrirMenu,
        enlaceActivo: enlaceActivo,
        setEnlaceActivo: setEnlaceActivo,
      }}
    >
      {children}
    </ContextoNavegacion.Provider>
  );
};
