import React from "react";

const BotonSimple = ({
  texto,
  deshabilitado,
  funcionClick,
  tipoDeBoton,
  colorBoton = "bg-primaxColorPrimario",
}) => {
  return (
    <button
      className={`w-28 h-40px p-1.5 rounded-lg mx-5 md:mx-10 ${
        deshabilitado ? "bg-gray-400" : colorBoton
      }  text-white  font-bold text-lg`}
      disabled={deshabilitado}
      onClick={funcionClick}
      type={tipoDeBoton}
    >
      {texto}
    </button>
  );
};

export default BotonSimple;
