import React, { useContext } from "react";
import { ContenedorPagina } from "../componentes/contenedores";
import { ArregloInputsModeloDODO } from "../utilidades";
import { FormularioDinamico } from "../componentes/formularios";
import { guardarInformacionEnLocalStorage } from "../utilidades";
import { TEXTO_VISUAL } from "../constantes";
import { obtenerModeloDoDo } from "../servicios";
import { ContextoAplicacion } from "../contextos";
import { ContenedorAgruparInformacion } from "../componentes/contenedores";
import { useModal } from "../hooks";
import moment from "moment";

const ModeloIndustria = () => {
  const contextoAplicacion = useContext(ContextoAplicacion);

  const { setModal } = contextoAplicacion;
  const [cerrarModal] = useModal();

  const crearModeloParaGuardar = async (datosFormulario) => {
    const modeloAGuardar = {
      capex: datosFormulario.capex,
      descuentoContratoAnterior: datosFormulario.descuentoContratoAnterior,
      diasPlazo: datosFormulario.diasPlazo,
      diasPlazoContratoAnterior: datosFormulario.diasPlazoContratoAnterior,
      diesel: datosFormulario.diesel,
      dieselNuevoTotal: datosFormulario.dieselNuevoTotal,
      extra: datosFormulario.extra,
      extraNuevoTotal: datosFormulario.extraNuevoTotal,
      fechaDesembolso: datosFormulario.fechaDesembolso,
      fechaInicioNuevoDescuento: datosFormulario.fechaInicioNuevoDescuento,
      fechaVencimientoContratoActual:
        datosFormulario.fechaVencimientoContratoActual,
      fechaVencimientoContratoActualFormateada:
        moment(datosFormulario.fechaVencimientoContratoActual)?.format(
          "MM/DD/YYYY"
        ) ?? "",
      fechaInicioNuevoDescuentoFormateada:
        moment(datosFormulario.fechaInicioNuevoDescuento)?.format(
          "MM/DD/YYYY"
        ) ?? "",
      fechaDesembolsoFormateada:
        moment(datosFormulario.fechaDesembolso)?.format("MM/DD/YYYY") ?? "",
      gasolinaCorriente: datosFormulario.gasolinaCorriente,
      gasolinaCorrienteNuevoTotal: datosFormulario.gasolinaCorrienteNuevoTotal,
      mapExistente: datosFormulario.mapExistente,
      maxProDiesel: datosFormulario.maxProDiesel,
      maxProDieselNuevoTotal: datosFormulario.maxProDieselNuevoTotal,
      mesesNuevoContrato: datosFormulario.mesesNuevoContrato,
      nombre: datosFormulario.nombre,
      nuevLEASE: datosFormulario.nuevLEASE,
      nuevasCondicionesComerciales:
        datosFormulario.nuevasCondicionesComerciales,
      nuevoDescuento: datosFormulario.nuevoDescuento,
      nuevoMAP: datosFormulario.nuevoMAP,
      planta: datosFormulario.planta,
      submercado: datosFormulario.submercado,
      tipoProyecto: datosFormulario.tipoProyecto,
      zonaFrontera: datosFormulario.zonaFrontera,
    };
    guardarInformacionEnLocalStorage(
      TEXTO_VISUAL.MODELO_INDUSTRIA,
      modeloAGuardar
    );
    contextoAplicacion.setCargando(true);
    try {
      const respuesta = await obtenerModeloDoDo(modeloAGuardar);
      if (respuesta?.status === 200) {
        const formateoInformacion = {
          Nombre: `${respuesta?.data?.nombre}`,
          Duración: `${respuesta?.data?.duracion} Años`,
          Volumen: `${respuesta?.data?.volumen} KGM`,
          "Volumen Contrato": `${respuesta?.data?.volumenContrato} KGAL`,
          Plazo: ` ${respuesta?.data?.plazo} Días`,
          Descuento: `${respuesta?.data?.descuento}MM`,
          Map: ` ${respuesta?.data?.map} $KCOP`,
          Tir: ` ${respuesta?.data?.tir}`,
          vpn: `${respuesta?.data?.vpn} KCOP$, @18%`,
        };
        contextoAplicacion.setCargando(false);
        setModal({
          abierto: true,
          titulo: "Consulta Modelo Industria",
          contenido: (
            <ContenedorAgruparInformacion
              datosParaOrganizar={formateoInformacion}
              porcentajeTirs={respuesta?.data?.porcentajeTirs}
            />
          ),
          botones: [
            {
              nombre: "Regresar",
              click: cerrarModal,
            },
          ],
        });
      }
    } catch (error) {
      console.log(error);
      contextoAplicacion.setCargando(false);
    }
  };
  return (
    <ContenedorPagina tituloPagina={TEXTO_VISUAL.MODELO_INDUSTRIA}>
      <FormularioDinamico
        arrayInputs={ArregloInputsModeloDODO}
        funcionGuardarModelo={crearModeloParaGuardar}
        formulario={TEXTO_VISUAL.MODELO_INDUSTRIA}
      />
    </ContenedorPagina>
  );
};

export default ModeloIndustria;
