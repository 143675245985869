export const ContenedorPagina = ({
  clasesCssAdicionales = "",
  tituloPagina = "",
  children,
}) => {
  return (
    <div
      className={`min-h-screen w-full flex flex-wrap items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover ${clasesCssAdicionales}`}
    >
      <div className="flex justify-center items-start flex-wrap w-full">
        <div className="w-full flex flex-wrap justify-center items-center">
          <span className="text-center text-21px font-bold text-primaxColorPrimario my-25px uppercase mb-5">
            {tituloPagina}
          </span>
        </div>
        <div className="bg-white w-95% md:w-5/6 p-4 rounded rounded-t-3xl rounded-b-3xl border border-opacity-50 border-primaxColorPrimario shadow-2xl">
          <div className="bg-white px-8 pt-2 rounded-t-3xl rounded-b-3xl">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
