import React, { useEffect, useState, useContext } from "react";
import { SeccionFormulario } from "../contenedores";
import {
  Input,
  AreaDeTexto,
  ListaDesplegable,
  InputMoneda,
  InputPorcentaje,
  ErroresInput,
} from "../inputs";
import { useForm, Controller } from "react-hook-form";
import { BotonSimple, BotonConImagen } from "../botones";
import { ContextoAplicacion } from "../../contextos";
import { useModal } from "../../hooks";
import { lupaNaranja, lupaGris } from "../../imagenes";

const FormularioDinamico = ({
  arrayInputs = [],
  funcionGuardarModelo = () => {},
  formulario = "",
}) => {
  const contextoAplicacion = useContext(ContextoAplicacion);

  const { setModal } = contextoAplicacion;
  const [cerrarModal] = useModal();

  const crearArrayValoresPorDefecto = () => {
    const camposNombres = {};

    arrayInputs.forEach((seccion) => {
      seccion?.contenido?.forEach((campo) => {
        camposNombres[campo?.nombre] = "";
      });
    });
    return camposNombres;
  };

  const crearArrayDeErroresIniciales = () => {
    const erroresFormulario = {};

    arrayInputs.forEach((seccion) => {
      seccion?.contenido?.forEach((campo) => {
        if (campo?.requerido) {
          erroresFormulario[campo?.nombre] = {
            message: "",
            ref: {
              name: campo?.nombre,
            },
            type: "required",
          };
        }
      });
    });
    return erroresFormulario;
  };
  const erroresIniciales = crearArrayDeErroresIniciales();

  const valoresIniciales = crearArrayValoresPorDefecto();

  const [
    deshabilitarCondicionesDeArriendo,
    setDeshabilitarCondicionesDeArriendo,
  ] = useState(false);

  const [deshabilitarVolumenActual, setDeshabilitarVolumenActual] =
    useState(false);

  const [deshabilitarCondicionesActuales, setDeshabilitarCondicionesActuales] =
    useState(false);

  const [existenCamposVacios, setExistenCamposVacios] = useState(false);

  const {
    trigger,
    handleSubmit,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: "onChange",
  });

  useEffect(() => {
    setExistenCamposVacios(Boolean(Object.keys(errors)?.length > 0)); //eslint-disable-next-line
  }, [Object.keys(errors)]);

  // const valorEsIgualACero = (e) => {
  //   if (parseInt(e) === 0) {
  //     return false;
  //   }
  //   return true;
  // }; NO SE ELIMINA POR SI SE NECESITA MAS ADELANTE

  const nombresCamposCondicionesDeArriendo = [
    "arriendoFijo",
    "arriendoVariable",
  ];
  const nombresCamposVolumenActual = [
    "gasolinaCorriente",
    "diesel",
    "extra",
    "maxProDiesel",
  ];

  const nombresCamposCondicionesActuales = [
    "mapExistente",
    "diasPlazoContratoAnterior",
    "descuentoContratoAnterior",
  ];

  const validarComportamientoLista = (e) => {
    if (e.target.name === "tipoNegocio") {
      setDeshabilitarCondicionesDeArriendo(
        Boolean(e.target.name === "tipoNegocio" && e.target.value === "Compra")
      );
      nombresCamposCondicionesDeArriendo?.map((elemento) => {
        return setValue(elemento, "");
      });
    }

    if (e.target.name === "tipoProyecto") {
      setDeshabilitarVolumenActual(
        Boolean(
          e.target.name === "tipoProyecto" &&
            (e.target.value === "NTI" || e.target.value === "Cambio de bandera")
        )
      );
      nombresCamposVolumenActual?.map((elemento) => {
        return setValue(elemento, "");
      });
      setDeshabilitarCondicionesActuales(
        Boolean(
          e.target.name === "tipoProyecto" &&
            (e.target.value === "NTI" ||
              e.target.value ===
                "Renovación Automática (Solo Aplica para Industria)" ||
              e.target.value === "Cambio de bandera")
        )
      );
      nombresCamposCondicionesActuales?.map((elemento) => {
        return setValue(elemento, "");
      });
    }
    trigger();
  };

  const validarCampoDebeDeshabilitarse = (nombre) => {
    if (nombresCamposCondicionesDeArriendo?.find((valor) => valor === nombre)) {
      return deshabilitarCondicionesDeArriendo;
    }
    if (nombresCamposVolumenActual?.find((valor) => valor === nombre)) {
      return deshabilitarVolumenActual;
    }
    if (nombresCamposCondicionesActuales?.find((valor) => valor === nombre)) {
      return deshabilitarCondicionesActuales;
    }
    return false;
  };

  const validarCampoRequerido = (requerido, nombre) => {
    if (nombresCamposCondicionesDeArriendo?.find((valor) => valor === nombre)) {
      return !deshabilitarCondicionesDeArriendo;
    }
    if (nombresCamposVolumenActual?.find((valor) => valor === nombre)) {
      return !deshabilitarVolumenActual;
    }
    if (nombresCamposCondicionesActuales?.find((valor) => valor === nombre)) {
      return !deshabilitarCondicionesActuales;
    }
    return requerido;
  };

  const validarCambioDeColor = (nombre) => {
    if (nombresCamposCondicionesDeArriendo?.find((valor) => valor === nombre)) {
      return !deshabilitarCondicionesDeArriendo;
    }
    if (nombresCamposVolumenActual?.find((valor) => valor === nombre)) {
      return !deshabilitarVolumenActual;
    }
    if (nombresCamposCondicionesActuales?.find((valor) => valor === nombre)) {
      return !deshabilitarCondicionesActuales;
    }
    return true;
  };

  const validarCamposVacios = () => {
    const titulosEncontrados = [];

    for (const nombreCampo of Object.keys(errors)) {
      for (const elemento of arrayInputs) {
        if (elemento.contenido) {
          const subelemento = elemento.contenido.find(
            (subelemento) => subelemento.nombre === nombreCampo
          );
          if (subelemento) {
            titulosEncontrados.push(subelemento.titulo);
          }
        }
      }
    }

    setModal({
      abierto: true,
      titulo: "Los siguientes campos son requeridos",
      contenido: (
        <div className="w-full flex flex-wrap justify-center items-center my-10">
          {titulosEncontrados?.map((elemento) => (
            <span className="w-full my-1 font-semibold">{elemento}</span>
          ))}
        </div>
      ),

      botones: [
        {
          nombre: "Regresar",
          click: cerrarModal,
        },
      ],
    });
  };

  const manejarTipoDeCampo = (elemento) => {
    switch (elemento.campo) {
      case "INPUT":
        return (
          <Controller
            name={elemento.nombre}
            control={control}
            rules={{
              required: validarCampoRequerido(
                elemento.requerido,
                elemento.nombre
              ),
              // validate:
              //   elemento.tipo === "number" && elemento.requerido
              //     ? valorEsIgualACero
              //     : null, DESHABILITADO PERO NO SE ELIMINA POR SI SE LLEGA A UTILIZAR
            }}
            render={({ field: { onChange, value, name } }) => (
              <>
                <Input
                  deshabilitado={validarCampoDebeDeshabilitarse(name)}
                  estilosInput={
                    Object.keys(errors).find(
                      (element) =>
                        element === elemento.nombre &&
                        validarCambioDeColor(name)
                    )
                      ? "appearance-none relative block w-full p-1.5 border border-primaxColorSecundario text-beltranColorGris  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm "
                      : "appearance-none relative block w-full p-1.5 border border-primaxColorPrimario text-beltranColorGris rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm "
                  }
                  erroresFormulario={errors}
                  estilosContenedor="w-full"
                  mostrarErrores={true}
                  placeholder={"Descripción"}
                  nombre={elemento.nombre}
                  tipo={elemento.tipo}
                  titulo={`${elemento.titulo}${
                    validarCampoRequerido(elemento.requerido, name) ? "*" : ""
                  }`}
                  valor={value}
                  onChange={onChange}
                />
                <ErroresInput
                  erroresFormulario={errors}
                  nombre={elemento.nombre}
                  tipoError={"validate"}
                  mensaje={"El valor no puede ser igual a Cero"}
                />
              </>
            )}
          />
        );
      case "AREA":
        return (
          <Controller
            name={elemento.nombre}
            control={control}
            rules={{
              required: elemento.requerido,
            }}
            render={({ field: { onChange, value } }) => (
              <AreaDeTexto
                estilosAreaDeTexto={
                  Object.keys(errors).find(
                    (element) => element === elemento.nombre
                  )
                    ? "h-20 mt-3 rounded w-full  p-1.5 border border-primaxSecundario text-beltranColorGris rounded-lg focus:outline-none  sm:text-sm"
                    : "h-20 mt-3 rounded w-full  p-1.5 border border-beltranColorPrimario text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm"
                }
                titulo={`${elemento.titulo}${elemento.requerido ? "*" : ""}`}
                valor={value}
                cambioValor={onChange}
              />
            )}
          />
        );
      case "LISTA":
        return (
          <Controller
            name={elemento.nombre}
            control={control}
            rules={{
              required: elemento.requerido,
              onChange: (e) => validarComportamientoLista(e),
            }}
            render={({ field: { onChange, value } }) => (
              <ListaDesplegable
                onChange={onChange}
                estilosLista={
                  Object.keys(errors).find(
                    (element) => element === elemento.nombre
                  )
                    ? "appearance-none relative block w-full p-1.5 border border-primaxColorSecundario text-beltranColorGris  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm "
                    : "appearance-none relative block w-full p-1.5 border border-primaxColorPrimario text-beltranColorGris rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm "
                }
                titulo={`${elemento.titulo}${elemento.requerido ? "*" : ""}`}
                opciones={elemento.opcionesLista}
                valor={value}
              />
            )}
          />
        );
      case "INPUTMONEDA":
        return (
          <Controller
            name={elemento.nombre}
            control={control}
            rules={{
              required: validarCampoRequerido(
                elemento.requerido,
                elemento.nombre
              ),
              // validate: elemento.requerido ? valorEsIgualACero : null, DESHABILITADO PERO NO SE ELIMINA POR SI SE LLEGA A UTILIZAR
            }}
            render={({ field: { onChange, value, name } }) => (
              <>
                <InputMoneda
                  onValueChange={(e) => {
                    onChange(e.floatValue);
                  }}
                  estilosContenedor={"w-full"}
                  estilosInput={
                    Object.keys(errors).find(
                      (element) =>
                        element === elemento.nombre &&
                        validarCambioDeColor(name)
                    )
                      ? "appearance-none relative block w-full p-1.5 border border-primaxColorSecundario text-beltranColorGris  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm "
                      : "appearance-none relative block w-full p-1.5 border border-primaxColorPrimario text-beltranColorGris rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm "
                  }
                  placeholder={"$"}
                  titulo={`${elemento.titulo}${
                    validarCampoRequerido(elemento.requerido, elemento.nombre)
                      ? "*"
                      : ""
                  }`}
                  valor={value}
                  deshabilitado={validarCampoDebeDeshabilitarse(name)}
                />
                <ErroresInput
                  erroresFormulario={errors}
                  nombre={elemento.nombre}
                  tipoError={"validate"}
                  mensaje={"El valor no puede ser igual a Cero"}
                />
              </>
            )}
          />
        );
      case "INPUTPORCENTAJE":
        return (
          <Controller
            name={elemento.nombre}
            control={control}
            rules={{
              required: validarCampoRequerido(
                elemento.requerido,
                elemento.nombre
              ),
              // validate: elemento.requerido ? valorEsIgualACero : null, NO SE ELIMINA POR SI ES NECESARIO MAS ADELANTE
            }}
            render={({ field: { onChange, value, name } }) => (
              <>
                <InputPorcentaje
                  estilosContenedor={"w-full"}
                  estilosInput={
                    Object.keys(errors).find(
                      (element) =>
                        element === elemento.nombre &&
                        validarCambioDeColor(name)
                    )
                      ? "appearance-none relative block w-full p-1.5 border border-primaxColorSecundario text-beltranColorGris  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm "
                      : "appearance-none relative block w-full p-1.5 border border-primaxColorPrimario text-beltranColorGris rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm "
                  }
                  placeholder={"%"}
                  titulo={`${elemento.titulo}${
                    validarCampoRequerido(elemento.requerido, elemento.nombre)
                      ? "*"
                      : ""
                  }`}
                  deshabilitarEscalaDecimal={true}
                  onValueChange={(e) => onChange(e.floatValue)}
                  valor={value}
                  onBlur={(e) =>
                    e.target.value === "%" ? setValue(elemento.nombre, 0) : null
                  }
                  deshabilitado={validarCampoDebeDeshabilitarse(name)}
                />
                <ErroresInput
                  erroresFormulario={errors}
                  nombre={elemento.nombre}
                  tipoError={"validate"}
                  mensaje={"El valor no puede ser igual a Cero"}
                />
              </>
            )}
          />
        );
      default:
        console.error(`Unknown field type: ${elemento.campo}`);
        return null;
    }
  };

  const evaluarNumeroDeCamposFaltantes = (longitudArreglo) => {
    if (longitudArreglo < 3) {
      return null;
    }
    if ((longitudArreglo + 1) % 3 === 0) {
      return (
        <div
          className={`w-45% lg:w-30% flex justify-start items-center flex-wrap mb-5`}
        ></div>
      );
    }
    return (
      <>
        <div
          className={`w-45% lg:w-30% flex justify-start items-center flex-wrap mb-5`}
        ></div>
        <div
          className={`w-45% lg:w-30% flex justify-start items-center flex-wrap mb-5`}
        ></div>
      </>
    );
  };

  const consultarExcel = (data) => {
    funcionGuardarModelo(data);
  };

  useEffect(() => {
    const datosPrevios = localStorage.getItem(formulario);
    if (datosPrevios) {
      const datosParseados = JSON.parse(datosPrevios);
      reset(datosParseados);
      setDeshabilitarCondicionesDeArriendo(
        Boolean(datosParseados?.tipoNegocio !== "Arriendo")
      );
      setDeshabilitarVolumenActual(
        Boolean(
          datosParseados?.tipoProyecto === "NTI" ||
            datosParseados?.tipoProyecto === "Cambio de bandera"
        )
      );
      setDeshabilitarCondicionesActuales(
        Boolean(
          datosParseados?.tipoProyecto === "NTI" ||
            datosParseados?.tipoProyecto ===
              "Renovación Automática (Solo Aplica para Industria)" ||
            datosParseados?.tipoProyecto === "Cambio de bandera"
        )
      );

      trigger();
    } else {
      trigger();
    }
    //eslint-disable-next-line
  }, [formulario, reset]);

  const limpiarFormulario = () => {
    reset(valoresIniciales);
    setDeshabilitarCondicionesDeArriendo(true);
    Object.keys(erroresIniciales).forEach((fieldName) => {
      if (erroresIniciales[fieldName]) {
        setError(fieldName, erroresIniciales[fieldName]);
      }
    });
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(consultarExcel)(e)}
      className="w-full justify-center items-center"
    >
      {arrayInputs.map((elemento, index) => (
        <SeccionFormulario
          className={` flex justify-center flex-wrap my-2`}
          key={index}
          tituloSeccion={elemento?.titulo}
        >
          {elemento?.contenido?.map((input, index) => (
            <div
              className={`${
                elemento?.contenido.length < 3
                  ? "w-full md:w-45% lg:w-45%"
                  : "w-full  md:w-45% lg:w-30%"
              } flex justify-start items-center flex-wrap mb-5`}
              key={index}
            >
              {manejarTipoDeCampo(input)}
            </div>
          ))}
          {evaluarNumeroDeCamposFaltantes(elemento?.contenido?.length)}
        </SeccionFormulario>
      ))}
      <div className="w-full flex items-center justify-center my-5">
        <BotonSimple
          texto={"Limpiar"}
          tipoDeBoton={"button"}
          funcionClick={limpiarFormulario}
          colorBoton={"bg-primaxColorSecundario"}
        />
        <BotonSimple
          texto={"Consultar"}
          tipoDeBoton={"submit"}
          deshabilitado={Object.keys(errors).length > 0}
        />
        <BotonConImagen
          estilosContenedor={"-ml-5"}
          estilosImagen={"w-8 h-8"}
          imagen={existenCamposVacios ? lupaNaranja : lupaGris}
          textoAlternativo={"Boton Agregar"}
          funcionAEjecutar={validarCamposVacios}
          desabilitado={!existenCamposVacios}
        />
      </div>
    </form>
  );
};

export default FormularioDinamico;
