import React from "react";
import { ErroresDeCampo } from "./ErroresDeCampo";

const Input = ({
  onChange,
  funcion,
  onBlur,
  tipo,
  placeholder,
  titulo,
  estilosInput,
  deshabilitado,
  fechaMinima,
  fechaMaxima,
  valor,
  onFocus,
  tabIndex,
  id,
  valorPorDefecto,
  mostrarErrores = false,
  erroresFormulario,
  nombre,
}) => {
  return (
    <div className={"w-full flex justify-start items-center flex-wrap"}>
      {titulo ? (
        <label className=" text-13px w-full text-primaxColorSecundario font-bold text-start my-5px">
          {titulo}
        </label>
      ) : null}
      <input
        className={estilosInput}
        type={tipo}
        placeholder={placeholder}
        disabled={deshabilitado}
        onChange={funcion ? (e) => funcion(e) : onChange}
        value={valor}
        min={fechaMinima}
        max={fechaMaxima}
        onBlur={onBlur}
        onFocus={onFocus}
        tabIndex={tabIndex}
        id={id}
        defaultValue={valorPorDefecto}
      />
      {mostrarErrores && (
        <ErroresDeCampo erroresFormulario={erroresFormulario} nombre={nombre} />
      )}
    </div>
  );
};

export default Input;
