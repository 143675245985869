import React from "react";
import { useForm } from "react-hook-form";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../configuracion/authConfig";
import logoPrimaxColor from "../imagenes/LogoPrimaxFull.png";
export default function Login() {
  const { instance, accounts } = useMsal();
  const { handleSubmit } = useForm();

  const onSubmit = () => {
    instance
      .loginRedirect(loginRequest)
      .then((res) => {
        instance
          .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          })
          .then((response) => {
            // callMsGraph(response.accessToken).then(response => setGraphData(response));
            sessionStorage.setItem("token", response.accessToken);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover">
        <div className=" sm:w-3/5 md:w-2/5 lg:w-1/3 shadow overflow-hidden space-y-8 border border-primaxColorSecundario border-opacity-25  rounded-lg  bg-white rounded-t-3xl rounded-b-3xl">
          <div className="flex flex-wrap h-64 justify-evenly items-center w-full bg-primaxColorPrimario bg-cover bg-center  rounded-t-3xl rounded-b-3xl">
            <div className="w-full md:w-10/12 flex justify-center bg-white rounded-full">
              <img
                className=" h-36 w-auto rounded-full border-2 border-white"
                src={logoPrimaxColor}
                alt="Workflow"
              />
            </div>
          </div>
          <div
            className="w-full flex justify-center text-center "
            style={{ marginTop: "-18px" }}
          >
            <p className="w-8/12 text-3xl md:text-4xl font-mono bg-white text-primaxColorSecundario font-bold  rounded-full">
              ECONOMICS APP
            </p>
          </div>

          {/* <div
            className="w-full text-center px-2"
            style={{ marginTop: "-1px" }}
          >
            <p className=" font-sans text-newCendiatra text-sm mt-3 font-bold">
              Gestión Administrativa Tecnológica Ocupacional
            </p>
          </div> */}
          <form
            className=" space-y-5 "
            onSubmit={handleSubmit(onSubmit)}
            method="POST"
          >
            <div className="flex justify-center">
              <button
                className="items-center mb-10 group relative w-32 h-10 flex justify-center py-2 px-4 bg-primaxColorPrimario border border-transparent text-md font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                type="submit"
              >
                Autenticarse
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
