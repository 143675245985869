import { restApi } from "./apiAxios";

export const obtenerModeloOd = async (payload) => {
  return await restApi({
    method: "POST",
    url: "/ModeloOD",
    data: payload,
  });
};

export const obtenerModeloDoDo = async (payload) => {
  return await restApi({
    method: "POST",
    url: "/ModeloDoDo",
    data: payload,
  });
};
