import React, { useEffect, useContext } from "react";
import { menuTresPuntos, logoPrimaxBlanco, imagenSalir } from "../../imagenes";
import Submenu from "./Submenu";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ContextoNavegacion } from "../../contextos";
import { RUTAS_APP } from "../../constantes";

import { useMsal } from "@azure/msal-react";

const MenuDinamico = ({ datos }) => {
  const location = useLocation();
  const { instance } = useMsal();
  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  const contextoNavegacion = useContext(ContextoNavegacion);
  // const applicationVersion = "V. 1." + process.env.REACT_APP_VERSION + " beta";

  useEffect(() => {
    abrirMenuAutomaticamente(); // eslint-disable-next-line
  }, [location]);

  const abrirMenuAutomaticamente = () => {
    if (location.pathname === "/") {
      contextoNavegacion.setAbrirMenu(true);
    }
  };

  const MostrarMenu = () => {
    if (contextoNavegacion.abrirMenu === true) {
      contextoNavegacion.setAbrirMenu(false);
      contextoNavegacion.setEnlaceActivo("");
    } else {
      contextoNavegacion.setAbrirMenu(true);
    }
  };

  useEffect(() => {
    contextoNavegacion.setEnlaceActivo(""); // eslint-disable-next-line
  }, [contextoNavegacion.setEnlaceActivo]);

  return (
    <>
      <div
        className={
          contextoNavegacion.abrirMenu
            ? " bg-transparent w-full h-full fixed flex justify-start content-start z-40 "
            : "hidden pointer"
        }
        onClick={contextoNavegacion.abrirMenu ? () => MostrarMenu() : null}
      ></div>
      <div
        className={
          contextoNavegacion.abrirMenu
            ? "bg-white w-full md:w-4/12 lg:w-4/12 xl:w-3/12 2xl:w-1/5 h-full fixed flex border  border-opacity-50 border-primaxColorPrimario shadow-2xl rounded flex-wrap justify-start  content-start z-50 rounded-tr-3xl rounded-br-3xl transition-color duration-100"
            : "bg-white flex border fixed  border-opacity-50 border-primaxColorPrimario shadow-2xl flex-wrap justify-start h-0 md:h-full content-start z-50 rounded-md transition-color duration-100"
        }
        onMouseLeave={() => contextoNavegacion.setEnlaceActivo("")}
      >
        <div
          className={
            contextoNavegacion.abrirMenu
              ? "bg-white h-5/6 rounded-lg w-full transition-color duration-75 "
              : "bg-white w-full h-5/6 rounded-lg transition-color duration-75"
          }
        >
          <div
            className={`${
              contextoNavegacion.abrirMenu ? "h-28" : "h-12 md:h-16"
            }  flex  justify-center items-center font-mon bg-primaxColorPrimario bg-center bg-cover ${
              contextoNavegacion.abrirMenu
                ? "w-full rounded-none md:rounded-tr-2xl  md:rounded-br-2xl"
                : "w-12 md:w-full rounded-md"
            } z-50 `}
            onMouseEnter={() => contextoNavegacion.setEnlaceActivo("")}
          >
            <div
              onClick={() => MostrarMenu()}
              className={
                contextoNavegacion.abrirMenu
                  ? "flex w-1/12 justify-center items-center cursor-pointer -mt-20"
                  : "flex w-4/12 justify-center items-center cursor-pointer -mt-10 ml-3 z-50"
              }
              style={{ marginBottom: "-30px" }}
            >
              <img
                className={
                  contextoNavegacion.abrirMenu
                    ? "h-6 w-2  text-white text-lg z-50 "
                    : "h-6 w-2 xl:w-2 2xl:w-2  text-white text-lg z-50 -ml-5 md:-ml-10"
                }
                src={menuTresPuntos}
                alt="menuTresPuntos"
              ></img>
            </div>
            <NavLink
              to={RUTAS_APP.PAGINA_PRINCIPAL}
              className={
                contextoNavegacion.abrirMenu
                  ? "w-full flex justify-center flex-wrap"
                  : "w-1/3 hidden  justify-center"
              }
            >
              <img
                className="w-full"
                src={logoPrimaxBlanco}
                alt="logo primax blanco"
              />
            </NavLink>
          </div>

          <div
            className={` ${
              contextoNavegacion.abrirMenu ? "w-full" : "w-12"
            } flex mt-5 flex-wrap`}
          >
            <div
              className={`w-full flex ${
                contextoNavegacion.abrirMenu
                  ? "justify-start"
                  : "justify-center"
              } justify-start items-center flex-wrap content-start`}
              style={{ minHeight: "400px" }}
            >
              {datos.map((elemento) => (
                <div
                  key={elemento.titulo}
                  className={
                    contextoNavegacion.abrirMenu
                      ? "flex flex-nowrap w-full"
                      : "hidden md:flex flex-nowrap w-full"
                  }
                >
                  <Submenu
                    titulo={elemento.titulo}
                    imagen={elemento.imagen}
                    ruta={elemento.ruta}
                    deshabilitado={elemento.deshabilitado}
                  >
                    {elemento.subMenus ? (
                      contextoNavegacion.enlaceActivo === elemento.titulo ? (
                        <div
                          className="w-auto fixed bg-white rounded z-50"
                          onClick={() => contextoNavegacion.setEnlaceActivo("")}
                          onMouseLeave={() =>
                            contextoNavegacion.setEnlaceActivo("")
                          }
                        >
                          {elemento.subMenus.map((subMenu) => (
                            <NavLink
                              to={subMenu.ruta}
                              className="w-full flex justify-start flex-nowrap items-center p-2 rounded border-t-2 border-l-2 border-r-2"
                            >
                              <img
                                className="w-7 h-7"
                                src={subMenu.imagen}
                                alt="service"
                              />
                              <span className="hover:bg-gray-100 ml-2 w-full">
                                {subMenu.titulo}
                              </span>
                            </NavLink>
                          ))}
                        </div>
                      ) : null
                    ) : null}
                  </Submenu>
                </div>
              ))}
              <div
                className={
                  contextoNavegacion.abrirMenu
                    ? "flex flex-nowrap w-full mt-12"
                    : "hidden md:flex flex-nowrap w-full mt-12"
                }
              >
                <Submenu
                  titulo="Cerrar Sesión"
                  imagen={imagenSalir}
                  funcion={() => handleLogout()}
                />
              </div>
            </div>
            <hr className="mt-5 mb-5  -ml-4"></hr>
            <div
              className={`flex flex-wrap ${
                contextoNavegacion.abrirMenu ? "" : "-ml-5"
              } `}
            ></div>

            <div
              className={
                contextoNavegacion.abrirMenu
                  ? "flex flex-wrap"
                  : "flex flex-wrap -ml-5"
              }
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuDinamico;
