import React from "react";
import { dividirPalabra } from "../../utilidades";

const ContenedorAgruparInformacion = ({
  datosParaOrganizar,
  porcentajeTirs = "",
}) => {
  return (
    <>
      <div className="w-full flex justify-start items-center flex-wrap my-10">
        {Object.entries(datosParaOrganizar).map(([key, value]) => (
          <div
            key={key}
            className="w-full md:w-2/6 flex justify-start items-center flex-wrap my-2"
          >
            <span className="w-full text-start text-primaxColorPrimario font-bold">
              {dividirPalabra(key)}
            </span>
            <span className="w-full text-start text-primaxColorSecundario">
              {dividirPalabra(value)}
            </span>
          </div>
        ))}
      </div>
      <div className="w-full flex justify-center items-center mt-5 mb-10 flex-wrap">
        <span className="w-full text-center text-primaxColorPrimario font-bold text-xl">
          La TIR mínima para este tipo de negocio en la planta seleccionada es:
        </span>
        <div
          className={`w-full text-center font-bold text-primaxColorSecundario text-2xl`}
        >
          {porcentajeTirs}
        </div>
      </div>
    </>
  );
};

export default ContenedorAgruparInformacion;
