import React from "react";

const BotonConImagen = ({
  imagen,
  estilosImagen,
  estilosContenedor,
  textoAlternativo,
  funcionAEjecutar,
  desabilitado,
  texto,
}) => {
  return (
    <div
      className={`${estilosContenedor} flex justify-center flex-wrap items-center`}
    >
      <button
        className={`w-full flex justify-center flex-wrap items-center ${
          desabilitado ? "pointer-events-none" : "cursor-pointer"
        }`}
        onClick={funcionAEjecutar}
        disabled={desabilitado}
        type={"button"}
      >
        <img
          className={`${estilosImagen}`}
          src={imagen}
          alt={textoAlternativo}
        />
      </button>
      {texto ?? <span className="w-full">{texto}</span>}
    </div>
  );
};

export default BotonConImagen;
