import React from "react";
import { Route, Routes } from "react-router";
import { RUTAS_APP } from "./constantes";
import {
  ModeloOd,
  ModeloDodo,
  ModeloIndustria,
  PaginaPrincipal,
} from "./paginas";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<PaginaPrincipal />} />
      <Route path={RUTAS_APP.FORMULARIOS.MODELO_OD} element={<ModeloOd />} />
      <Route
        path={RUTAS_APP.FORMULARIOS.MODELO_DODO}
        element={<ModeloDodo />}
      />
      <Route
        path={RUTAS_APP.FORMULARIOS.MODELO_INDUSTRIA}
        element={<ModeloIndustria />}
      />
    </Routes>
  );
};
export default Router;
